<template>
  <div v-if="doctor">
    <v-list>
      <v-form ref="form" v-model="valid">
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Department
              </div>
              <v-autocomplete
                v-model="doctor.department"
                :items="departments"
                :filter="$util.departmentFilter"
                :disabled="!doedit"
                hide-details="auto"
                :dense="!$root.mobile"
                :label="$root.mobile ? 'Department' : ''"
                mandatory
                :outlined="doedit"
                :solo="!doedit"
                flat
                item-text="name"
                item-value="id"
              >
                <template v-slot:selection="data">
                  <v-avatar
                    :color="data.item.avatar ? '' : 'primary'"
                    size="15"
                    :tile="data.item.avatar ? true : false"
                    class="pushx"
                    ><v-img
                      v-if="data.item.avatar"
                      :src="
                        $root.assetsBaseURL +
                          '' +
                          data.item.avatar.data.asset_url
                      "
                      height="14"
                      width="14"
                      contain
                    />
                    <div
                      v-else
                      style="text-transform: uppercase;width:100%"
                      class="white--text"
                    >
                      {{ data.item.name.charAt(0) }}
                    </div> </v-avatar
                  >{{ data.item.name }}
                </template>
                <template v-slot:item="data">
                  <v-avatar
                    :color="data.item.avatar ? '' : 'primary'"
                    size="20"
                    :tile="data.item.avatar ? true : false"
                    class="pushx"
                    ><v-img
                      v-if="data.item.avatar"
                      :src="
                        $root.assetsBaseURL +
                          '' +
                          data.item.avatar.data.asset_url
                      "
                      height="14"
                      width="14"
                      contain
                    />
                    <div
                      v-else
                      style="text-transform: uppercase;width:100%"
                      class="white--text"
                    >
                      {{ data.item.name.charAt(0) }}
                    </div> </v-avatar
                  >{{ data.item.name }}
                </template>
              </v-autocomplete>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Appointment Duration
              </div>
              <v-text-field
                :disabled="!doedit"
                hide-details="auto"
                :rules="durationRule"
                suffix="Mintues"
                :messages="['Duration can be 15,30,45 or 60 mintues']"
                :dense="!$root.mobile"
                :label="$root.mobile ? 'Appointment Duration' : ''"
                :outlined="doedit"
                :solo="!doedit"
                flat
                v-model="doctor.call_duration"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Practice Started
              </div>
              <v-text-field
                :disabled="!doedit"
                hide-details="auto"
                :rules="requiredFeildRule"
                v-mask="'####'"
                suffix="year"
                :dense="!$root.mobile"
                :label="$root.mobile ? 'Practice Started' : ''"
                :outlined="doedit"
                :solo="!doedit"
                flat
                v-model="doctor.practice_started"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Clinic Fees
              </div>
              <v-text-field
                :disabled="!doedit"
                hide-details="auto"
                :rules="requiredFeildRule"
                :dense="!$root.mobile"
                :label="$root.mobile ? 'Clinic Fees' : ''"
                :outlined="doedit"
                :solo="!doedit"
                flat
                v-model="doctor.clinic"
                :prefix="doctor.id ? $util.getLocalCurrency() : '£'"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Online Fees
              </div>
              <v-text-field
                :disabled="!doedit"
                hide-details="auto"
                :rules="requiredFeildRule"
                :dense="!$root.mobile"
                :label="$root.mobile ? 'Online Fees' : ''"
                :outlined="doedit"
                :solo="!doedit"
                flat
                v-model="doctor.video"
                :prefix="doctor.id ? $util.getLocalCurrency() : '£'"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Languages Spoken
              </div>
              <v-autocomplete
                v-model="doctor.languages_spoken"
                :items="langs"
                :rules="requiredFeildRule"
                multiple
                chips
                :filter="$util.langFilter"
                :disabled="!doedit"
                hide-details="auto"
                :dense="!$root.mobile"
                :label="$root.mobile ? 'Languages Spoken' : ''"
                :outlined="doedit"
                :solo="!doedit"
                @change="sl_change = true"
                flat
                item-text="name"
                item-value="id"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :close="doedit"
                    @click="data.select"
                    @click:close="remove(data.item)"
                    dense
                    color="blue lighten-5"
                    class="primary--text"
                  >
                    <v-avatar left>
                      <img
                        :src="
                          $root.assetsBaseURL +
                            '/public/doctory/assets/' +
                            data.item.icon
                        "
                        alt=""
                      />
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-avatar class="pushxl" size="25">
                    <img
                      :src="
                        $root.assetsBaseURL +
                          '/public/doctory/assets/' +
                          data.item.icon
                      "
                      alt=""
                    /> </v-avatar
                  >{{ data.item.name }}
                </template>
              </v-autocomplete>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Bio
              </div>
              <v-textarea
                :disabled="!doedit"
                hide-details="auto"
                :rules="requiredFeildRule"
                auto-grow
                rows="2"
                :dense="!$root.mobile"
                :label="$root.mobile ? 'Bio' : ''"
                :outlined="doedit"
                :solo="!doedit"
                flat
                v-model="doctor.bio"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-subheader
              class="m-0 p-0"
              style="font-size:1.1rem"
              v-if="$root.mobile"
              >Social Media Accounts</v-subheader
            >

            <v-row class="p-0">
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-text-field
                  :disabled="!doedit"
                  hide-details="auto"
                  :dense="!$root.mobile"
                  :outlined="doedit"
                  :solo="!doedit"
                  flat
                  v-model="doctor.social.twitter"
                  prepend-inner-icon="mdi-twitter"
                />
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-text-field
                  :disabled="!doedit"
                  hide-details="auto"
                  :dense="!$root.mobile"
                  :outlined="doedit"
                  :solo="!doedit"
                  flat
                  v-model="doctor.social.fb"
                  prepend-inner-icon="mdi-facebook"
                />
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-text-field
                  :disabled="!doedit"
                  hide-details="auto"
                  :dense="!$root.mobile"
                  :outlined="doedit"
                  :solo="!doedit"
                  flat
                  v-model="doctor.social.instagram"
                  prepend-inner-icon="mdi-instagram"
                />
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-text-field
                  :disabled="!doedit"
                  hide-details="auto"
                  :dense="!$root.mobile"
                  :outlined="doedit"
                  :solo="!doedit"
                  flat
                  v-model="doctor.social.linkedin"
                  prepend-inner-icon="mdi-linkedin"
                />
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-form>
      <v-card-actions
        v-if="doctor.id"
        class="p-4"
        style="height:64px;display:flex;width:100%;justify-content: flex-end;align-items: center;background: white;
    "
      >
        <v-btn
          :loading="updating"
          :disabled="updating"
          @click="update"
          block
          elevation="0"
          rounded
          large
          color="primary"
        >
          Save Practice Updates
        </v-btn>
      </v-card-actions>
    </v-list>
  </div>
  <v-row align="center" justify="center" style="height:10vh" v-else>
    <v-progress-circular color="primary" indeterminate />
  </v-row>
</template>
<script>
export default {
  name: "DoctorProfile",
  components: {},
  props: ["uid"],
  data() {
    return {
      doedit: true,
      updating: false,
      doctor: null,
      departments: null,
      langs: null,
      valid: false,
      sl_change: false,
      requiredFeildRule: [v => !!v || "Required Field"],
      durationRule: [
        v =>
          v == "" ||
          v == "15" ||
          v == "30" ||
          v == 45 ||
          v == 60 ||
          "Invalid Duration"
      ]
    };
  },
  beforeMount() {},
  activated() {
    // console.log("activated medical info")
    if (this.doctor == null) {
      if (this.uid) this.loadDoctor();
      else this.doctor = { languages_spoken: [], social: {} };
    }
  },
  deactivated() {
    this.doctor = null;
  },
  async created() {
    this.langs = this.$util.data.langs.data;
    console.log("langs are ", this.langs);
    this.departments = await this.$department.getDepartments();
  },
  mounted() {},
  methods: {
    async update() {
      if (this.isValid()) {
        const tempdepart =
          typeof this.doctor.department === "object"
            ? this.doctor.department.id
            : this.doctor.department;
        const payload = {
          department: tempdepart,
          practice_started: this.doctor.practice_started + "-01-01",
          call_duration:
            this.doctor.call_duration == "" ? null : this.doctor.call_duration,
          clinic: (this.doctor.clinic / this.$util.getLocalRate()).toFixed(2),
          video: (this.doctor.video / this.$util.getLocalRate()).toFixed(2),
          bio: this.doctor.bio,
          social: this.doctor.social
        };
        // console.log("object to upload", payload)
        this.updating = true;
        if (this.sl_change) {
          console.log(
            "this.doctor.languages_spoken",
            this.doctor.languages_spoken
          );
          let langs = [];
          if (!Number.isInteger(this.doctor.languages_spoken[0])) {
            langs = this.doctor.languages_spoken.map(i => {
              return i.id;
            });
          } else {
            langs = this.doctor.languages_spoken;
          }
          await this.$doctor.updateSpokenLanguages(this.doctor.id, langs);
        }
        await this.$doctor.updateDoctor(this.doctor.id, payload).then(data => {
          if (data.status == 200) {
            this.updating = false;
            this.sl_change = false;
            this.$doctor.getItemByID(this.doctor.doctor_id, true);
            this.$doctor.updateDoctorCache(this.doctor.doctor_id.id);
          }
        });
      }
    },
    reset() {
      // console.log("resetting doctor form");
      this.$refs.form.resetValidation();
      this.sl_change = false;
      this.doctor = {
        department: null,
        practice_started: "",
        clinic: "",
        video: "",
        bio: "",
        call_duration: null,
        languages_spoken: [],
        social: {}
      };
    },
    isValid() {
      return this.$refs.form.validate();
    },
    getDoctor() {
      return this.doctor;
    },
    remove(item) {
      this.sl_change = true;
      const index = this.doctor.languages_spoken.findIndex(i => {
        return i.id === item.id;
      });
      if (index >= 0) this.doctor.languages_spoken.splice(index, 1);
    },
    async loadDoctor() {
      let temp = await this.$doctor.getItemByID(this.uid, true);
      console.log("temp", temp);
      await this.$doctor.getItemByID(this.uid, true).then(async data => {
        console.log("loaded doctor data is", data);

        let tempDepartment = this.departments.find(i => {
          return i.id == data.department.id;
        });
        data.department = tempDepartment;
        data.languages_spoken = data.languages_spoken.map(l => {
          return l.languages_id;
        });
        if (data.social == null) data.social = {};
        await this.$util.getExchangeRate().then(rate => {
          if (data.clinic != "" && data.clinic != null)
            data.clinic = (data.clinic * rate).toFixed(2);
          if (data.video != "" && data.video != null)
            data.video = (data.video * rate).toFixed(2);
        });

        this.doctor = data;
      });
    }
  },
  computed: {}
};
</script>
